$(document).ready(() => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('#scroll-page').css('padding-top', '60px');

        $('a[href*="#"]').on('click', (e) => {
            // hashchange($(e.target).attr('href').substr(1));
            var hash = $(e.target).attr('href').substr(1);
            var section = $('#scroll-page .section[data-hash=' + hash + ']');
            if (section.length > 0) {
                $("html, body").stop().animate({scrollTop:section.offset().top}, 1000, 'swing');

                // $(window).scrollTop(section.offset().top);
            }
            var sectionHorizontal = $('#scroll-page .scroll-horizontal[data-hash=' + hash + ']');
            if (sectionHorizontal.length > 0) {
                $("html, body").stop().animate({scrollTop:sectionHorizontal.offset().top}, 1000, 'swing');

                // $(window).scrollTop(sectionHorizontal.offset().top);
            }
        });

        return false;
    } else {
        $('html, body').css('overflow', 'hidden');
    }

    var target;
    var targetHorizontal;
    var root = $('body');
    var parent = $('#scroll-page');
    var children = parent.children('.section');
    var endAnimation = true;
    var direction = 'DOWN';

    up = () => {
        // console.log('UP');
        direction = 'UP';
        if (target.hasClass('horizontal')) {
            if (targetHorizontal == undefined) {
                next = $(target.children('.scroll-horizontal')[target.children('.scroll-horizontal').length - 1]);
            } else {
                next = targetHorizontal.prev('.scroll-horizontal');
            }
            animHorizontal(next);
            if (next.length > 0) {
                return false;
            }
        }
        prev = target.prev('.section');
        anim(prev);
    }

    down = () => {
        // console.log('DOWN');
        direction = 'DOWN';
        if (target == undefined) {
            target = $(children[0]);
        }
        if (target.hasClass('horizontal')) {
            if (targetHorizontal == undefined) {
                next = $(target.children('.scroll-horizontal')[0]);
            } else {
                next = targetHorizontal.next('.scroll-horizontal');
            }
            animHorizontal(next);
            if (next.length > 0) {
                return false;
            }
        }
        next = target.next('.section');
        anim(next);
    }

    anim = (next) => {
        if (endAnimation) {
            endAnimation = false;
            if (next.length > 0) {
                var hash = next.attr('data-hash');
                if (next.hasClass('horizontal')) {
                    hash = $(next.children('.scroll-horizontal')[direction == 'DOWN' ? 0 : next.children('.scroll-horizontal').length - 1]).attr('data-hash');
                }
                if (hash != undefined) {
                    window.location.hash = hash;
                }
                parent.css('transform', 'translate3d(0px, -' + next[0].offsetTop + 'px, 0px)');
                target = next;
            }
            endAnimation = true;
        }
    }

    animHorizontal = (next) => {
        if (endAnimation) {
            endAnimation = false;
            if (next.length > 0) {
                var hash = next.attr('data-hash');
                if (hash != undefined) {
                    
                    // REVISAR por que no funciona el hash de servicios, el primero
                    // antes del horizontal

                    window.location.hash = hash;
                }
                next.parent('.horizontal').css('transform', 'translate3d(-' + next[0].offsetLeft + 'px, 0px, 0px)');
                targetHorizontal = next;
            }
            endAnimation = true;
        }
    }

    // children.on('mouseenter', (event) => {
    //     target = $(event.currentTarget);
    // });

    // childrenHorizontal.on('mouseenter', (event) => {
    //     targetHorizontal = $(event.currentTarget);
    // });

    root.on('mousewheel DOMMouseScroll', (e) => {
        if (typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
            if (e.originalEvent.detail > 0) {
                down();
            } else if (e.originalEvent.detail < 0) {
                up();
            }
        } else if (typeof e.originalEvent.wheelDelta == 'number') {
            if (e.originalEvent.wheelDelta < 0) {
                down();
            } else if (e.originalEvent.wheelDelta > 0) {
                up();
            }
        }
    });

    // window.onhashchange = () => {
    //     var hash = window.location.hash.slice(1);
    //     if (hash) {
    //         var section = $('#scroll-page .section[data-hash=' + hash + ']');
    //         if (section.length > 0) {
    //             anim(section);
    //         }
    //         var sectionHorizontal = $('#scroll-page .scroll-horizontal[data-hash=' + hash + ']');
    //         if (sectionHorizontal.length > 0) {
    //             anim(sectionHorizontal.parent('.horizontal'));
    //             animHorizontal(sectionHorizontal);
    //         }
    //     } else {
    //         target = $(children[0]);
    //         // targetHorizontal = $(childrenHorizontal[0]);
    //     }
    // }

    // window.onhashchange();

    // window.onhashchange = (e) => {
    //     console.log(onhashchange);
    // };

    $('a[href*="#"]').on('click', (e) => {
        hashchange($(e.target).attr('href').substr(1));
    });

    hashchange = (hash) => {
        if (hash) {
            var section = $('#scroll-page .section[data-hash=' + hash + ']')
            if (section.length > 0) {
                anim(section);
            }
            var sectionHorizontal = $('#scroll-page .scroll-horizontal[data-hash=' + hash + ']')
            if (sectionHorizontal.length > 0) {
                anim(sectionHorizontal.parent('.horizontal'));
                animHorizontal(sectionHorizontal);
            }
        }
    }

    var hash = window.location.hash.slice(1);
    if (hash != undefined) {
        hashchange(hash);
    }

})